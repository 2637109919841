<template>
  <div>
    <div class="orgCertBox">
      <img src="@/assets/image/productCenter/orgCertBanner.png" alt="机构证书">
      <h2>机构证书</h2>
      <p>机构证书由合法CA机构在遵循国际国内相关标准规范的基础上颁发，用来证明单位、组织在互联网上的数字身份。机构证书中包含机构信息和密钥信息，可应用于工商、税务、金融、社保、政府采购、行政办公等一系列的电子政务、电子商务活动。</p>
    </div>
    <v-functional :list="functional" />
    <v-featuresItem :list="features" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import featuresItem from './components/featuresItem.vue';
import functionalItem from './components/functionalItem.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'orgCert-icon1.png',
          title: '密钥匹配唯一',
          intro:
            '采用PKI/CA技术，依靠非对称加密算法中密钥对匹配的唯一性，来确保企业在互联网上的合法身份。',
        },
        {
          icon: 'orgCert-icon2.png',
          title: '有效电子签章',
          intro:
            '结合PKI数字证书技术，实现在各类电子文档上添加合法有效电子签章。',
        },
        {
          icon: 'orgCert-icon3.png',
          title: '同等法律效力',
          intro:
            '将电子印章以传统印章图案的形式嵌入到电子文档中，与相应纸质签章具备同等法律效力。',
        },
      ],
      features: [
        {
          title: '合法合规',
          intro:
            '豸信CA是合法合规的第三方CA机构，严格遵照《中华人民共和国电子签名法》和相关国际国内标准，为各类组织颁发单位证书，安全有效。',
        },
        {
          title: '安全可靠',
          intro:
            '以数字证书为核心的加密技术可以对网络上传输的信息进行加解密、数字签名、验证，确保交易实体身份真实可信。',
        },
      ],
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-featuresItem': featuresItem,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.orgCertBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 1.18rem 0.53rem 1.12rem;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 4.2rem;
    margin-bottom: 0.89rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>